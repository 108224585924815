import React, { useContext, useEffect, useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form, Input, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Link } from 'gatsby';
import LocalizedStrings from 'react-localization';
import { StringParam, useQueryParam } from 'use-query-params';

import { ClientHelmet } from '../components';
import { FormPageLayout } from '../components/Layouts';
import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess } from '../utility';

const GATSBY_STYLE_FORM_PAGE_FORM_BTN_BORDER_COLOR = process.env.GATSBY_STYLE_FORM_PAGE_FORM_BTN_BORDER_COLOR!;

const { Title, Paragraph } = Typography;
const strings = new LocalizedStrings({
    en: {
        resetTitle: 'Reset your Virtooally Password',
        resetSuccess: 'Your password has been reset. Please proceed to login.',
        newPass: 'New Password',
        enterPass: 'Please enter your password',
        enterPassBelow: 'Enter your new secret below',
        reset: 'Reset password',
        backToLogin: 'Back to login',
        authNotFound: 'Auth module not found',
    },
    de: {
        resetTitle: 'Setzen Sie Ihr Virtooally-Passwort zurück',
        resetSuccess: 'Ihr Passwort wurde zurückgesetzt. Bitte loggen Sie sich erneut ein. ',
        newPass: 'Neues Passwort',
        enterPass: 'Bitte geben Sie Ihr Passwort ein',
        enterPassBelow: 'Bitte geben Sie Ihr neues Passwort ein',
        reset: 'Passwort zurücksetzen',
        backToLogin: 'Zurück zum Login',
        authNotFound: 'Authentifizierung konnte nicht erfolgen',
    },
});

const ResetPasswordPage = () => {
    const context = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useQueryParam('t', StringParam);
    const { eventAuthModule, fetchEvent } = context;
    const breakpoint = useBreakpoint();

    useEffect(() => {
        fetchEvent().catch(handleError);
    }, []);

    const onFinish = async ({ newPassword }: { newPassword: string }) => {
        try {
            setLoading(true);
            if (!eventAuthModule) throw new Error(strings.authNotFound);
            const response = await apiRequester.resetPassword({
                eventAuthModule,
                newPassword,
                token: token ? token : '',
            });
            handleSuccess(strings.resetSuccess!);
            setLoading(false);
        } catch (err) {
            handleError(err);
            setLoading(false);
        }
    };
    return (
        <FormPageLayout>
            <ClientHelmet />
            <div style={{ marginBottom: '2rem' }}>
                <Title
                    level={2}
                    style={{ marginBottom: '0', color: 'white', textAlign: breakpoint.md ? 'left' : 'center' }}
                >
                    {strings.reset}
                </Title>
                <Paragraph style={{ color: 'white', textAlign: breakpoint.md ? 'left' : 'center' }}>
                    {strings.enterPassBelow}
                </Paragraph>
            </div>
            <Form onFinish={onFinish}>
                <Form.Item
                    label={<span style={{ color: 'white' }}>{strings.newPass}</span>}
                    labelCol={{ span: 24 }}
                    name="newPassword"
                    rules={[{ required: true, message: strings.enterPass }]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: breakpoint.md ? 'row' : 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            style={{
                                borderColor: GATSBY_STYLE_FORM_PAGE_FORM_BTN_BORDER_COLOR,
                                marginBottom: breakpoint ? '1rem' : undefined,
                            }}
                        >
                            {strings.reset}
                        </Button>
                        <Link
                            style={{ color: 'white' }}
                            to={`/login${typeof window !== 'undefined' ? window.location.search : ''}`}
                        >
                            <ArrowLeftOutlined /> {strings.backToLogin}
                        </Link>
                    </div>
                </Form.Item>
            </Form>
        </FormPageLayout>
    );
};

export default ResetPasswordPage;
